import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { withPreview } from "gatsby-source-prismic-graphql";

import { BootstrapNavbar } from "./common/bootstrap-navbar.component"
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"

const query = graphql`
    query headerQuery {
        prismic {
            allHeaders {
                edges {
                    node {
                        primary_navigation {
                            link {
                                ...link
                            }
                            link_style
                            link_text
                        }
                        logo_dark
                        logo_light
                        body {
                            ... on PRISMIC_HeaderBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;
const Header = ({theme}) => (
 <StaticQuery query={query} render={withPreview(data => {
   const navbar = data.prismic.allHeaders.edges[0];
   if (navbar) {
   return (
     <header className="header">
        <BootstrapNavbar
          logoLight={navbar.node.logo_light}
          logoDark={navbar.node.logo_dark}
          theme={theme}
          menu={navbar.node.primary_navigation}
          className="header-navbar mb-5 navbar-expand-xl bg-transparent text-left"
        />
         <Slices body={navbar.node.body}/>
     </header>
   )
   }
   return null;
 }, query, [linkFragment])}/>
)
export default Header
